<template>
  <base-layout
    show-back-btn="false"
    page-default-back-link=""
    show-info="true"
  >
    <div v-if="!dataError">
      <ion-text
        color="primary"
        class="ion-text-center title-headline"
      >
        <h1>Find nærmeste servicetekniker</h1>
      </ion-text>
      <ion-label>Postnummer</ion-label>
      <div class="search-box">
        <ion-item>
          <ion-icon
            :icon="icons.search"
            slot="start"
          ></ion-icon>
          <ion-input
            type="tel"
            clear-input
            clear-on-edit="true"
            placeholder="Indtast Postnummer"
            v-model="postnr"
            required
            @input="clearError()"
            v-on:keyup.enter="goToResult()"
          ></ion-input>
        </ion-item>
      </div>
      <ion-item-divider v-if="errorMessage.length > 0">
        <ion-label>
          {{ errorMessage }}
        </ion-label>
      </ion-item-divider>

      <ion-button
        expand="block"
        @click="goToResult()"
        class="search-btn"
        size="large"
      >Søg</ion-button>
      <ion-button
        expand="block"
        router-link="/work"
        class="search-btn"
        color="secondary"
        size="large"
      >Vagtskema</ion-button>
      <ion-button
        expand="block"
        router-link="/calendar"
        class="search-btn"
        color="secondary"
        size="large"
      >Kalender</ion-button>
    </div>
    <div v-else>
      <h1>Der skete en fejl!</h1>
      <p>Prøv igen senere eller kontakt en ansvarlig!</p>
    </div>
    <!-- <ion-fab
      vertical="bottom"
      horizontal="end"
      slot="fixed"
    >
      <ion-fab-button
        router-link="/panel"
        color="secondary"
      >
        <ion-icon :icon="icons.admin"></ion-icon>
      </ion-fab-button>
    </ion-fab> -->
  </base-layout>
</template>

<script>
import { informationCircle, search, cogOutline } from "ionicons/icons";
import { mapGetters } from "vuex";

import {
  IonButton,
  IonItem,
  IonLabel,
  IonInput,
  IonCard,
  IonCardContent,
  IonIcon,
  IonText,
  IonList,
  IonItemDivider,
  IonFab,
  IonFabButton,
} from "@ionic/vue";

export default {
  components: {
    IonButton,
    IonItem,
    IonLabel,
    IonInput,
    IonCard,
    IonCardContent,
    IonIcon,
    IonText,
    IonList,
    IonItemDivider,
    IonFab,
    IonFabButton,
  },
  computed: {
    ...mapGetters(["dataError", "dawaPost"]),
  },
  data() {
    return {
      postnr: "",
      errorMessage: "",
      icons: {
        info: informationCircle,
        search: search,
        admin: cogOutline,
      },
    };
  },
  methods: {
    clearError() {
      this.errorMessage = "";
    },
    goToResult() {
      if (this.postnr.length == 4) {
        var dataPostnr = this.dawaPost.find((x) => x.nr == this.postnr);
        if (dataPostnr) {
          this.$router.push({
            path: "/results",
            query: { postnr: this.postnr },
          });
        } else {
          this.errorMessage = "Postnummer findes ikke";
        }
      } else {
        this.errorMessage = "Udfyld korrekt postnummer";
      }
    },
  },
};
</script>

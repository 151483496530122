export default {
    message(state) {
        return state.message;
    },
    data(state) {
        return state.data;
    },
    workPlanData(state) {
        return state.workPlanData;
    },
    loginData(state) {
        return state.loginData;
    },
    dawaPost(state) {
        return state.dawaPost;
    },
    dataError(state) {
        return state.dataError;
    },
    containerName(state) {
        return state.containerName;
    },
    infoTitel(state) {
        return state.infoTitel;
    },
    infoContent(state) {
        return state.infoContent;
    },
    dataLoaded(state) {
        return state.dataLoaded;
    },
    isAuthenticated: state => !!state.user,
    StateUser: state => state.user,
};
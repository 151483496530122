<template>
  <ion-app>
    <div
      v-if="!dataLoaded"
      class="spinner"
    >
      <ion-spinner
        color="primary"
        name="bubbles"
      ></ion-spinner>
    </div>
    <ion-router-outlet v-if="dataLoaded" />

  </ion-app>
</template>

<script>
import { mapGetters } from "vuex";
import { IonApp, IonRouterOutlet, IonSpinner } from "@ionic/vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "App",
  components: {
    IonApp,
    IonRouterOutlet,
    IonSpinner
  },
  computed: {
    ...mapGetters(["dataLoaded"]),
  },
  mounted() {
    this.getDataFromStorage();    
  },
  methods: {
    getDataFromStorage() {
      this.$store.dispatch("listContainers");
    },
  },
});
</script>
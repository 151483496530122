<template>
  <ion-page>
    <ion-header>
      <ion-toolbar color="tertiary">
        <ion-buttons
          slot="start"
          v-if="showBackBtn == 'true'"
        >
          <ion-back-button :default-href="pageDefaultBackLink"></ion-back-button>
        </ion-buttons>
        <ion-title
          router-link="/"
          class="header-title"
        >
          <ion-icon
            :icon="icons.logo"
            size="large"
          ></ion-icon>
          <ion-text>Mortalin</ion-text>
        </ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <slot />
      <ion-fab
        v-if="showInfo  == 'true'"
        vertical="bottom"
        horizontal="start"
        slot="fixed"
      >
        <ion-fab-button @click="openModal">
          <ion-icon :icon="icons.info"></ion-icon>
        </ion-fab-button>
      </ion-fab>
    </ion-content>
  </ion-page>
</template>

<script>
import { information } from "ionicons/icons";
import Modal from "../app/Modal";

import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonButtons,
  IonBackButton,
  modalController,
  IonFab,
  IonFabButton,
  IonIcon,
  IonText,
} from "@ionic/vue";

export default {
  props: ["pageDefaultBackLink", "showInfo", "showBackBtn"],
  data() {
    return {
      icons: {
        info: information,
        logo: require("../../../public/assets/mortalin.svg"),
      },
    };
  },
  components: {
    IonPage,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonButtons,
    IonBackButton,
    IonFab,
    IonFabButton,
    IonIcon,
    IonText,
  },
  methods: {
    async openModal() {
      const modal = await modalController.create({
        component: Modal,
        componentProps: {
          title: "New Title",
        },
      });
      return modal.present();
    },
  },
};
</script>
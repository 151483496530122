export default {
    saveData(state, data) {
        state.data = data;
    },
    saveWorkPlanData(state, workPlanData) {
        state.workPlanData = workPlanData;
    },
    saveLoginData(state, loginData) {
        state.loginData = loginData;
    },
    saveDawaPost(state, payload) {
        state.dawaPost = payload;
    },
    setErrorData(state, payload) {
        state.dataError = payload;
    },
    setContainerName(state, payload) {
        state.containerName = payload;
    },
    setInfoTitelAndContent(state, payload) {
        state.infoTitel = payload.titel;
        state.infoContent = payload.content;
    },
    setUser(state, username) {
        state.user = username;
    },
    setDataLoaded(state, payload) {
        state.dataLoaded = payload;
    }
};
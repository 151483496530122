import {
  createRouter,
  createWebHistory
} from '@ionic/vue-router';
import store from '../store/store';
import SearchPage from '../pages/SearchPage.vue';
import ResultsPage from '../pages/ResultsPage.vue';
import LoginPage from '../pages/LoginPage.vue';


const routes = [{
    path: '/',
    component: SearchPage,
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/results',
    component: ResultsPage,
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/info',
    component: () => import('../pages/InfoPage.vue'),
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/panel',
    component: () => import('../pages/ControlPanelPage.vue'),
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/work',
    component: () => import('../pages/WorkPlanPage.vue'),
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/calendar',
    component: () => import('../pages/CalendarPage.vue'),
    meta: {
      requiresAuth: true
    },
  },
  {
    path: "/login",
    name: "Login",
    component: LoginPage,
    meta: {
      guest: true
    },
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/'
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  store.dispatch('validUser');
  if (to.matched.some((record) => record.meta.requiresAuth)) {    
    if (store.getters.isAuthenticated) {
      next();
      return;
    }
    next("/login");
  } else {
    next();
  }
});

router.beforeEach((to, from, next) => {
  store.dispatch('validUser');
  if (to.matched.some((record) => record.meta.guest)) {
    if (store.getters.isAuthenticated) {
      next("/");
      return;
    }
    next();
  } else {
    next();
  }
});

export default router
<template>
  <ion-card>
    <div class="triangle"></div>
    <ion-item>
      <ion-card-content
        class="person-card"
        v-if="!showPhone"
      >
        <ion-label>
          <ion-text color="primary">{{ person.Initialer.toUpperCase() }}</ion-text>
        </ion-label>
        <ion-label class="person-name">{{ person.Navn }} {{ person.Efternavn }}</ion-label>
        <ion-label class="person-name">{{ person.Postnummer }} {{ person.By }}</ion-label>
        <span class="card-distance">{{ person.distance }} km</span>
        <div class="filters">
          <img
            class="filter-icon"
            :src="dogImgUrl"
            alt="Hund"
          >
          <img
            class="filter-icon"
            :src="aspImgUrl"
            alt="Aspirant"
          >
          <img
            class="filter-icon"
            :src="townImgUrl"
            alt="Kommunal"
          >
          <img
            class="filter-icon"
            :src="skytteImgUrl"
            alt="Skytte"
          >
        </div>
        <div
          class="call-person"
          @click="showPhone = true"
        >
          <img
            src="../../../public/assets/icon/call.png"
            alt=""
          >
        </div>
      </ion-card-content>
      <ion-card-content
        class="person-card"
        v-else
      >
        <ion-text class="text-align-center">Ring til {{ person.Initialer }}</ion-text>
        <ion-grid>
          <ion-row>
            <ion-col v-if="person.MortalinMobil">
              <ion-item :href="'tel:'+ person.MortalinMobil">
                <ion-icon
                  :icon="icons.logo"
                  size="large"
                  slot="start"
                ></ion-icon>
                <ion-label>
                  <h2>Arbejde</h2>
                  <p>{{ format(person.MortalinMobil) }}</p>
                </ion-label>
              </ion-item>
            </ion-col>
            <ion-col v-if="person.MobilArbejde">
              <ion-item :href="'tel:'+ person.MobilArbejde">
                <ion-icon
                  :icon="icons.phone"
                  size="large"
                  slot="start"
                ></ion-icon>
                <ion-label>
                  <h2>Arbejde mobil</h2>
                  <p>{{ format(person.MobilArbejde) }}</p>
                </ion-label>
              </ion-item>
            </ion-col>
            <ion-col v-if="person.PrivatMobil">
              <ion-item :href="'tel:'+ person.PrivatMobil">
                <ion-icon
                  :icon="icons.mobile"
                  size="large"
                  slot="start"
                ></ion-icon>
                <ion-label>
                  <h2>Privat mobil</h2>
                  <p>{{ format(person.PrivatMobil) }}</p>
                </ion-label>
              </ion-item>
            </ion-col>
          </ion-row>
        </ion-grid>
        <ion-icon
          :icon="icons.close"
          slot="start"
          class="info-icon close-icon"
          @click="showPhone = false"
        ></ion-icon>
      </ion-card-content>

    </ion-item>

  </ion-card>
</template>

<script>
import {
  IonList,
  IonItem,
  IonLabel,
  IonCard,
  IonCardContent,
  IonText,
  IonIcon,
  IonGrid,
  IonRow,
  IonCol,
} from "@ionic/vue";

import { close, phonePortraitSharp, callSharp } from "ionicons/icons";

export default {
  props: ["person"],
  components: {
    IonList,
    IonItem,
    IonLabel,
    IonCard,
    IonCardContent,
    IonText,
    IonIcon,
    IonGrid,
    IonRow,
    IonCol,
  },
  data() {
    return {
      showPhone: false,
      icons: {
        close: close,
        phone: callSharp,
        mobile: phonePortraitSharp,
        logo: require("../../../public/assets/mortalin.svg"),
      },
    };
  },
  computed: {
    dogImgUrl() {
      var grey = this.person.Hund == "x" ? "" : "-grey";
      return require("../../../public/assets/icon/dog" + grey + ".png");
    },
    aspImgUrl() {
      var grey = this.person.Aspirant == "x" ? "" : "-grey";
      return require("../../../public/assets/icon/aspirant" + grey + ".png");
    },
    townImgUrl() {
      var grey = this.person.Kommunal == "x" ? "" : "-grey";
      return require("../../../public/assets/icon/town" + grey + ".png");
    },
    skytteImgUrl() {
      var grey = this.person.Skytte == "x" ? "" : "-grey";
      return require("../../../public/assets/icon/skytte" + grey + ".png");
    },
  },
  methods: {
    format(s) {
      return s.toString().replace(/\d{4}(?=.)/g, "$& ");
    },
  },
};
</script>

<template>
  <base-layout
    show-back-btn="false"
    page-default-back-link=""
    show-info="false"
  >
    <ion-text
      color="primary"
      class="ion-text-center title-headline"
    >
      <h1>Log ind</h1>
    </ion-text>
    <div class="login">
      <div>
        <form @submit.prevent="submit" v-on:keyup.enter="submit">
          <ion-item>
            <ion-label
              position="floating"
              for="username"
            >Brugernavn</ion-label>
            <ion-input
              type="text"
              name="username"
              v-model="form.username"
            >
            </ion-input>
          </ion-item>
          <ion-item>
            <ion-label
              position="floating"
              for="password"
            >Kodeord</ion-label>
            <ion-input
              type="password"
              name="password"
              inputmode="numeric"
              v-model="form.password"
            >
            </ion-input>
          </ion-item>
          <ion-button
            class="mt-20"
            expand="full"
            type="submit"
          >Log ind</ion-button>
        </form>
        <p
          v-if="showError"
          id="error"
        >Brugernavn eller kodeord er ikke korrekt.</p>
      </div>
    </div>
  </base-layout>
</template>

<script>
import { mapActions } from "vuex";

import {
  IonButton,
  IonItem,
  IonLabel,
  IonInput,
  IonIcon,
  IonText,
} from "@ionic/vue";

export default {
  name: "Login",
  components: {
    IonButton,
    IonItem,
    IonLabel,
    IonInput,
    IonIcon,
    IonText,
  },
  data() {
    return {
      form: {
        username: "",
        password: "",
      },
      showError: false,
    };
  },
  methods: {
    ...mapActions(["LogIn"]),
    async submit() {
      const User = new FormData();
      User.append("username", this.form.username);
      User.append("password", this.form.password);
      try {
        var response = this.LogIn(User);
        
        response.then((result) => {
          if (result) {
            this.$router.push("/");
            this.showError = false;
          } else {
            this.showError = true;
          }
        });
      } catch (error) {
        this.showError = true;
      }
    },
  },
};
</script>
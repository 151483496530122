<template>
  <ion-header>
    <ion-toolbar>
      <ion-title>{{ infoTitel }}</ion-title>
      <ion-button
        slot="end"
        @click="dismissModal()"
      >X</ion-button>
    </ion-toolbar>
  </ion-header>
  <ion-content class="ion-padding" v-html="infoContent">
  </ion-content>
</template>

<script>
import { mapGetters } from "vuex";
import {
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
  modalController,
  IonButton,
} from "@ionic/vue";

export default {
  name: "Modal",
  props: ["title"],
  data() {
    return {
      content: "Content",
    };
  },
  computed: {
    ...mapGetters(["infoTitel", "infoContent"]),
  },
  components: { IonContent, IonHeader, IonTitle, IonToolbar, IonButton },
  methods: {
    dismissModal() {
      modalController.dismiss();
    },
  },
};
</script>
const initialState = {
    user: null,
    data: [],
    workPlanData: [],
    loginData: [],
    dawaPost: [],
    dataError: false,
    containerName: null,
    infoTitel: "Info titel",
    infoContent: "<p><b>Hejsa!</b></p>",
    dataLoaded: false,
};

export default Object.assign({}, initialState);